import { mapGetters } from "vuex"

export default {
  name: "Dashboard",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      loggedInUserPolicies      : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages  : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport: "kpis/issuesWithUnansweredReport",
      isLoadingKpis             : "kpis/isLoadingKpis",
      loggedInUserRoleTypes     : "auth/loggedInUserRoleTypes"
    }),
    hasAccessToCommunicationKpi() {
      return !!this.loggedInUserPolicies["Issue view"]
    }
  }
}